import { styled } from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';

export const AlertMessageWrapper = styled('div', {
  padding: 10,
  borderRadius: 4,
  marginBottom: '$spacing-fluid-block-medium',
  textAlign: 'center',
  variants: {
    error: {
      true: {
        background: 'pink',
        border: '1px solid tomato',
      },
    },
    info: {
      true: {
        background: '#FFF3D6',
        border: '1px solid #FDE199',
      },
    },
  },
});

const SecondLineMessage = styled('div', {
  marginBottom: '0',
});

interface AlertMessageProps {
  firstLineMessage: string;
  secondLineMessage?: string;
  variant: 'error' | 'info';
}

export const AlertBanner = ({
  firstLineMessage,
  secondLineMessage,
  variant,
}: AlertMessageProps) => {
  const { t } = useTranslation();
  return (
    <AlertMessageWrapper error={variant === 'error'} info={variant === 'info'}>
      {t(firstLineMessage)}

      {secondLineMessage && (
        <SecondLineMessage>{t(secondLineMessage)}</SecondLineMessage>
      )}
    </AlertMessageWrapper>
  );
};
