/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { StyledInput } from './OrderProductRowStyled';

export interface Props {
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  value: string;
  minimum?: number;
  error?: boolean;
  maximum?: number;
}

const NumberInput = ({
  value,
  onChange,
  onBlur,
  minimum = 1,
  maximum,
  error = false,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = e.target.value.length === 0;

    setInputValue(e.target.value);

    const passToParent = isEmpty ? value : e.target.value.replace(/^0+/, '');

    if (passToParent) {
      onChange(passToParent);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const isEmpty = e.target.value.length === 0;

    const passToParent = isEmpty ? value : e.target.value;

    setInputValue(passToParent);
    onBlur(passToParent);
  };

  return (
    <StyledInput
      error={error}
      placeholder="0"
      min={minimum}
      max={maximum}
      name="numberOfPacks"
      type="number"
      value={inputValue}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

export default NumberInput;
