import { Label, LabelSize, LabelWeight } from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import {
  CreditSelectMode,
  useBasketContext,
} from '../../providers/BasketProvider/BasketProvider';
import { getPrice } from '../../utils/getPrice/getPrice';
import { PaymentDisclaimer } from '../Nav/NavBasketDropdown/NavBasketDropdownStyled';
import { OrderDetailsCardWrapper } from '../OrderDetailsCard/OrderDetailsCardStyled';
import NumberInput from '../Orders/OrderProductRow/NumberInput';
import { Row, RowItem, TextLabel } from './OrderSummaryStyled';

interface OrderCreditsProps {
  currency: string;
}

const OrderCredits = ({ currency }: OrderCreditsProps) => {
  const { t } = useTranslation();

  const {
    creditSelectMode,
    handleSelectCreditSelectMode,
    usableCredits,
    totalCredits,
    selectedCredits,
    handleSelectCredits,
  } = useBasketContext();

  if (!totalCredits) return null;

  const credits = usableCredits ? usableCredits : 0;

  const computedTotalCredits = totalCredits
    ? getPrice(totalCredits, currency)
    : 0;

  const currencySymbol = (0)
    .toLocaleString(currency, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  const remainingCredits = getPrice(
    selectedCredits ? totalCredits - selectedCredits : totalCredits,
    currency
  );

  const getRadioStyles = (selected: boolean) => {
    return selected
      ? {
          color: '$colour-brand-neutral-hue-1-shade-1',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }
      : {
          color: '$colour-brand-neutral-hue-1-shade-1',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '5px',
        };
  };

  return (
    <OrderDetailsCardWrapper css={{ marginTop: '10px' }}>
      <Row>
        <RowItem>
          <TextLabel
            css={{ marginBottom: '10px' }}
            size={LabelSize.Large}
            weight={LabelWeight['Semi-Bold']}
            darkGrey
          >
            {t('viewBasket.availableCredit')}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel
            size={LabelSize.Large}
            weight={LabelWeight['Regular']}
            darkGrey
          >
            {computedTotalCredits}
          </TextLabel>
        </RowItem>
      </Row>

      <Label
        as="label"
        size={LabelSize.Small}
        css={getRadioStyles(
          creditSelectMode === CreditSelectMode.useAvailableCredits
        )}
      >
        <input
          type="radio"
          style={{ marginRight: '10px' }}
          checked={creditSelectMode === CreditSelectMode.useAvailableCredits}
          onClick={() => {
            if (creditSelectMode !== CreditSelectMode.useAvailableCredits) {
              handleSelectCredits(credits);
              handleSelectCreditSelectMode(
                CreditSelectMode.useAvailableCredits
              );
            }
          }}
        />
        {t('viewBasket.useAvailableCredit')}
      </Label>
      {creditSelectMode === CreditSelectMode.useAvailableCredits && (
        <PaymentDisclaimer
          css={{
            display: 'inline-block',
            marginTop: '0',
            marginBottom: '15px',
          }}
        >
          <Label
            size={LabelSize.Small}
            css={{
              color: '$colour-brand-neutral-hue-1-base',
              fontFamily: '$font-latin-sans-serif-1',
            }}
          >
            {t('viewBasket.creditRemaining', {
              credit: remainingCredits,
            })}
          </Label>
        </PaymentDisclaimer>
      )}
      <Label
        as="label"
        size={LabelSize.Small}
        css={getRadioStyles(
          creditSelectMode === CreditSelectMode.useSelectedCredits
        )}
      >
        <input
          type="radio"
          style={{ marginRight: '10px' }}
          checked={creditSelectMode === CreditSelectMode.useSelectedCredits}
          onClick={() => {
            if (creditSelectMode !== CreditSelectMode.useSelectedCredits) {
              handleSelectCredits(credits);
              handleSelectCreditSelectMode(CreditSelectMode.useSelectedCredits);
            }
          }}
        />
        {t('viewBasket.usePartialCredit')}
      </Label>
      {creditSelectMode === CreditSelectMode.useSelectedCredits && (
        <PaymentDisclaimer
          css={{
            display: 'inline-block',
            marginTop: '0',
            marginBottom: '15px',
          }}
        >
          <Label
            size={LabelSize.Small}
            css={{
              color: '$colour-brand-neutral-hue-1-base',
              fontFamily: '$font-latin-sans-serif-1',
            }}
          >
            {t('viewBasket.creditRemaining', {
              credit: remainingCredits,
            })}
          </Label>
        </PaymentDisclaimer>
      )}
      <Label
        as="label"
        size={LabelSize.Small}
        css={{
          color: '$colour-brand-neutral-hue-1-shade-1',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <input
          type="radio"
          style={{ marginRight: '10px' }}
          checked={creditSelectMode === CreditSelectMode.dontUseCredits}
          onClick={() => {
            if (creditSelectMode !== CreditSelectMode.dontUseCredits) {
              handleSelectCredits(0);
              handleSelectCreditSelectMode(CreditSelectMode.dontUseCredits);
            }
          }}
        />
        {t('viewBasket.dontUseCredit')}
      </Label>

      {creditSelectMode === CreditSelectMode.useSelectedCredits && (
        <>
          <TextLabel
            css={{ marginTop: '20px', marginBottom: '10px' }}
            size={LabelSize.Medium}
            weight={LabelWeight['Regular']}
            darkGrey
          >
            {t('viewBasket.applyCreditBalance')}
          </TextLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {currencySymbol}
            <NumberInput
              maximum={credits}
              onBlur={value => {
                if (Number(value) > credits) {
                  handleSelectCredits(credits);
                } else if (Number(value) <= 0) {
                  handleSelectCredits(1);
                } else {
                  handleSelectCredits(Number(Number(value).toFixed(2)));
                }
              }}
              value={`${selectedCredits}`}
              onChange={e => {
                handleSelectCredits(Number(e));
              }}
            />
          </div>
        </>
      )}
    </OrderDetailsCardWrapper>
  );
};

export default OrderCredits;
