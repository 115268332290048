import {
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Label,
  LabelLineHeight,
  LabelSize,
  LabelWeight,
  Title,
  TitleSize,
  Tooltip,
} from '@sourceful/design-system';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { UK_CURRENCY } from '../../../constants';
import { useBasketContext } from '../../../providers/BasketProvider/BasketProvider';
import { useClientOrderContext } from '../../../providers/BasketProvider/ClientOrderProvider';
import { BasketLine } from '../../../providers/BasketProvider/useBasket';
import { useOrganisationsContext } from '../../../providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { Divider } from '../../../styles/SharedStyles';
import { getPrice } from '../../../utils/getPrice/getPrice';
import { getTaxRate } from '../../../utils/getTaxRate/getTaxRate';
import { DoubleDisplay } from '../../DoubleDisplay/DoubleDisplay';
import { getDeliveryFeeText } from '../../Orders/utils';
import { NavBasketDropdownDeletedProductCard } from './NavBasketDropdownDeletedProductCard';
import { NavBasketDropdownProductCard } from './NavBasketDropdownProductCard';
import {
  BasketLinesWrapper,
  BasketProductCounter,
  Box,
  ButtonWrapper,
  DeletedBasketLinesWrapper,
  DeletedProductCardWrapper,
  DropdownMenu,
  DropdownMenuActions,
  DropdownMenuContent,
  DropdownMenuTrigger,
  NumberOfExcessProductsInBasketWrapper,
  PaymentDisclaimer,
  Separator,
  SummaryDataWrapper,
  SummaryWrapper,
} from './NavBasketDropdownStyled';
import { ScrollArea, ScrollAreaViewport } from './ScrollbarStyled';
const MAX_VISIBLE_BASKET_LINES = 3;

interface NavBasketDropdownProps {
  closeResponsiveMenu: () => void;
}

export const NavBasketDropdown = ({
  closeResponsiveMenu,
}: NavBasketDropdownProps) => {
  const {
    basketLines,
    basketSummary,
    markProductAsDeletedInBasket,
    markProductAsNonDeletedInBasket,
    removeMarkAsDeletedProducts,
    selectedCredits,
    totalCredits,
  } = useBasketContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { placeOrder, isPlacingOrder } = useClientOrderContext();
  const { currentOrganisation } = useOrganisationsContext();

  const placeOrderEnabled =
    !currentOrganisation?.config?.enableClientReferenceCollection;

  const active = matchPath('/basket', pathname);

  const currencyCode = currentOrganisation?.defaultCurrency || UK_CURRENCY;

  // handling sorted & deleted basket lines
  const deletedBasketLines = basketLines.filter(({ isDeleted }) => isDeleted);
  const sortedDeletedBasketLines = deletedBasketLines.sort(
    (line1, line2) => line2.addedAtTimestamp - line1.addedAtTimestamp
  );
  const nonDeletedBasketLines = basketLines.filter(
    ({ isDeleted }) => !isDeleted
  );
  const sortedBasketLines = nonDeletedBasketLines.sort(
    (line1, line2) => line2.addedAtTimestamp - line1.addedAtTimestamp
  );
  const visibleBasketLines = sortedBasketLines.slice(
    0,
    MAX_VISIBLE_BASKET_LINES
  );
  const numberOfNotVisibleLines =
    sortedBasketLines.length - MAX_VISIBLE_BASKET_LINES;
  const excessBasketLines =
    numberOfNotVisibleLines > 0 ? numberOfNotVisibleLines : 0;

  const handleDeleteOfBasketLine = useCallback(
    (basketLine: BasketLine) => {
      if (basketLine.product.id) {
        markProductAsDeletedInBasket(basketLine.product.id);
      }
    },
    [basketLines]
  );

  const handleUndoDeleteOfBasketLine = useCallback(
    (basketLine: BasketLine) => {
      if (basketLine.product.id) {
        markProductAsNonDeletedInBasket(basketLine.product.id);
      }
    },
    [basketLines]
  );

  // basket costs & taxes
  const basketSubtotal = getPrice(basketSummary.productsSubTotal, currencyCode);

  const VATPercentage = getTaxRate(
    currentOrganisation?.addresses[0].taxRates,
    'VAT'
  ).taxPercentage;
  const computedVAT = getPrice(basketSummary.vat, currencyCode);

  const tariffPercentage =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'Tariff')
      .taxPercentage || null;

  const computedTariff = basketSummary.tariff
    ? getPrice(basketSummary.tariff, currencyCode)
    : null;

  const credits = selectedCredits ? selectedCredits : 0;
  const computedCredits = getPrice(credits, currencyCode);

  const hasTariff = computedTariff && tariffPercentage;

  const basketTotalIncVAT = getPrice(
    basketSummary.basketTotalIncludingTaxes - credits,
    currencyCode
  );

  const basketSubtotalWithDelivery = getPrice(
    basketSummary.basketSubTotal,
    currencyCode
  );
  const taxInfoText = hasTariff
    ? t('basketDropdown.basketTotalInclTaxes')
    : t('basketDropdown.basketTotalInclVAT');

  // address IDs and visibility changes
  const addressesIds = sortedBasketLines.flatMap(basketLine => {
    return basketLine.addressLines.map(({ address }) => address.id);
  });
  const dedupedAddressesIds = Array.from(new Set(addressesIds));
  const distinctNumberOfLocations = dedupedAddressesIds.length;

  const handleMenuVisibilityChange = useCallback(
    (open: boolean) => {
      closeResponsiveMenu();
      setIsOpen(open);
      if (!open) {
        removeMarkAsDeletedProducts();
      }
    },
    [isOpen, basketLines]
  );

  const toggleMenuVisibility = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const isBatchOrderCorrect = basketLines.every(
    basketLine => basketLine.overflowQuantity === 0
  );

  const viewBasket = useCallback(() => {
    setIsOpen(false);
    navigate('/basket');
  }, [isOpen]);

  const onPlaceOrder = useCallback(async () => {
    await placeOrder({
      isDraft: false,
    });
    setIsOpen(false);
  }, [isOpen]);

  return (
    <Box>
      <DropdownMenu open={isOpen} onOpenChange={handleMenuVisibilityChange}>
        <DropdownMenuTrigger asChild onClick={toggleMenuVisibility}>
          <Box>
            <Tooltip
              trigger={
                <ButtonWrapper>
                  <Button
                    data-cy="basket-dropdown-nav-button"
                    radii={ButtonRadii['X-Small']}
                    size={ButtonSize.Medium}
                    variant={ButtonVariant.Tertiary}
                    css={
                      active
                        ? {
                            color: '$colour-brand-blue-hue-1-base !important',
                            backgroundColor: '$colour-lightest !important',
                            paddingInlineStart:
                              '$spacing-static-inline-x-small',
                            paddingInlineEnd: '$spacing-static-inline-x-small',
                          }
                        : {
                            paddingInlineStart:
                              '$spacing-static-inline-x-small',
                            paddingInlineEnd: '$spacing-static-inline-x-small',
                          }
                    }
                    colourOverride={{
                      active: {
                        color: '$colour-brand-blue-hue-1-base !important', // colour.ui.light.container.neutral.no-contrast.text.blue.lo
                        backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
                      },
                      default: {
                        color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                      },
                      hover: {
                        color: '$colour-brand-neutral-hue-1-shade-2', // colour.ui.light.container.neutral.xx-lo.text.neutral.hi
                      },
                      visited: {
                        color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                      },
                    }}
                  >
                    <Icon
                      size={IconSize['X-Small']}
                      name="icon-shopping-basket"
                    />
                  </Button>
                  {nonDeletedBasketLines.length > 0 && (
                    <BasketProductCounter data-cy="basket-counter">
                      {nonDeletedBasketLines.length > 9
                        ? '9+'
                        : nonDeletedBasketLines.length}
                    </BasketProductCounter>
                  )}
                </ButtonWrapper>
              }
              contentText={t('viewProducts.viewBasket')}
            />
          </Box>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <ScrollArea>
            <ScrollAreaViewport>
              {basketLines.length === 0 && (
                <Label
                  size={LabelSize.Large}
                  lineHeight={LabelLineHeight.Loose}
                  weight={LabelWeight['Semi-Bold']}
                  css={{ fontFamily: '$font-latin-sans-serif-1' }}
                >
                  <Title size={TitleSize.Small}>
                    {t('basketDropdown.emptyBasket')}
                  </Title>
                </Label>
              )}

              {basketLines.length > 0 && (
                <>
                  <Label
                    size={LabelSize.Large}
                    lineHeight={LabelLineHeight.Loose}
                    weight={LabelWeight['Semi-Bold']}
                    css={{
                      fontFamily: '$font-latin-sans-serif-1',
                      color: '$colour-brand-neutral-hue-1-shade-2',
                    }}
                  >
                    <Title size={TitleSize.Small}>
                      {t('basketDropdown.basketTitle', {
                        count: sortedBasketLines.length,
                      })}
                    </Title>
                  </Label>
                  {}
                  <BasketLinesWrapper>
                    {sortedDeletedBasketLines.length > 0 && (
                      <DeletedBasketLinesWrapper>
                        {sortedDeletedBasketLines.map((basketLine, index) => {
                          return sortedDeletedBasketLines.length > 1 &&
                            index !== 0 ? (
                            <DeletedProductCardWrapper
                              key={basketLine.product.id}
                            >
                              <NavBasketDropdownDeletedProductCard
                                basketLine={basketLine}
                                onUndoDelete={handleUndoDeleteOfBasketLine}
                              />
                            </DeletedProductCardWrapper>
                          ) : (
                            <NavBasketDropdownDeletedProductCard
                              key={basketLine.product.id}
                              basketLine={basketLine}
                              onUndoDelete={handleUndoDeleteOfBasketLine}
                            />
                          );
                        })}
                      </DeletedBasketLinesWrapper>
                    )}

                    {visibleBasketLines.map((basketLine, index) => (
                      <NavBasketDropdownProductCard
                        key={basketLine.product.id}
                        onDelete={handleDeleteOfBasketLine}
                        basketLine={basketLine}
                        noBottomRadius={
                          visibleBasketLines.length > 1 && index === 0
                        }
                        noRadius={
                          visibleBasketLines.length > 2 &&
                          ![0, visibleBasketLines.length - 1].includes(index)
                        }
                        noTopRadius={
                          visibleBasketLines.length > 1 &&
                          index === visibleBasketLines.length - 1
                        }
                        noBottomBorder={
                          (visibleBasketLines.length > 1 && index === 0) ||
                          (visibleBasketLines.length > 2 &&
                            ![0, visibleBasketLines.length - 1].includes(index))
                        }
                      />
                    ))}
                  </BasketLinesWrapper>
                  {excessBasketLines > 0 && (
                    <NumberOfExcessProductsInBasketWrapper>
                      <Separator />
                      <Label
                        size={LabelSize.Medium}
                        lineHeight={LabelLineHeight.Normal}
                        weight={LabelWeight['Regular']}
                        css={{
                          paddingLeft: '$spacing-fluid-block-small',
                          paddingRight: '$spacing-fluid-block-small',
                          color: '$colour-brand-neutral-hue-1-base',
                          fontFamily: '$font-latin-sans-serif-1',
                        }}
                      >
                        {t(
                          'basketDropdown.numberOfExcessProductsInBasketLabelText',
                          { count: excessBasketLines }
                        )}
                      </Label>
                      <Separator />
                    </NumberOfExcessProductsInBasketWrapper>
                  )}

                  <SummaryWrapper>
                    <Label
                      size={LabelSize.Medium}
                      weight={LabelWeight['Semi-Bold']}
                      css={{
                        fontFamily: '$font-latin-sans-serif-1',
                        color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                      }}
                    >
                      {t('viewBasket.summary')}
                    </Label>
                    <SummaryDataWrapper>
                      <DoubleDisplay
                        css={{
                          paddingTop: 4,
                          paddingBottom: 4,
                        }}
                        left={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1',
                            }}
                          >
                            {t('basketDropdown.products')}
                          </Label>
                        }
                        right={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {!currentOrganisation?.config?.prepaidStock
                              ? basketSubtotal
                              : t('basketDropdown.prepaid')}
                          </Label>
                        }
                      />
                      <DoubleDisplay
                        css={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: '$colour-brand-neutral-hue-1-shade-1',
                        }}
                        left={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {t('basketDropdown.deliveryLabel', {
                              count: distinctNumberOfLocations,
                            })}
                          </Label>
                        }
                        right={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {getDeliveryFeeText({
                              notApplicableText: t(
                                'orderDetails.notApplicable'
                              ),
                              deliveryFee: basketSummary.deliverySubTotal,
                              currencyCode: currencyCode,
                            })}
                          </Label>
                        }
                      />
                      <Divider margin="smallMargin" />
                      <DoubleDisplay
                        css={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: '$colour-brand-neutral-hue-1-shade-1',
                        }}
                        left={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {t('basketDropdown.basketSubTotal')}
                          </Label>
                        }
                        right={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {basketSubtotalWithDelivery}
                          </Label>
                        }
                      />
                      <DoubleDisplay
                        css={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: '$colour-brand-neutral-hue-1-shade-1',
                        }}
                        left={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {hasTariff
                              ? t('basketDropdown.taxExempt')
                              : t('basketDropdown.vat', {
                                  VATPercentage: VATPercentage,
                                })}
                          </Label>
                        }
                        right={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                          >
                            {computedVAT}
                          </Label>
                        }
                      />
                      {!!selectedCredits && (
                        <DoubleDisplay
                          css={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            color: '$colour-brand-neutral-hue-1-shade-1',
                          }}
                          left={
                            <Label
                              size={LabelSize.Small}
                              css={{
                                fontFamily: '$font-latin-sans-serif-1',
                                color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                              }}
                            >
                              {t('basketDropdown.credit')}
                            </Label>
                          }
                          right={
                            <Label
                              size={LabelSize.Small}
                              css={{
                                fontFamily: '$font-latin-sans-serif-1',
                                color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                              }}
                            >
                              -{computedCredits}
                            </Label>
                          }
                        />
                      )}
                      {hasTariff && (
                        <DoubleDisplay
                          css={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            color: '$colour-brand-neutral-hue-1-shade-1',
                          }}
                          left={
                            <Label
                              size={LabelSize.Small}
                              css={{
                                fontFamily: '$font-latin-sans-serif-1',
                                color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                              }}
                            >
                              {t('basketDropdown.tariff', {
                                tariffPercentage: tariffPercentage,
                              })}
                            </Label>
                          }
                          right={
                            <Label
                              size={LabelSize.Small}
                              css={{
                                fontFamily: '$font-latin-sans-serif-1',
                                color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                              }}
                            >
                              {computedTariff}
                            </Label>
                          }
                        />
                      )}
                      <Divider margin="smallMargin" />
                      <DoubleDisplay
                        css={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: '$colour-brand-neutral-hue-1-shade-2',
                        }}
                        left={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                            weight={LabelWeight['Semi-Bold']}
                          >
                            {taxInfoText}
                          </Label>
                        }
                        right={
                          <Label
                            size={LabelSize.Small}
                            css={{
                              fontFamily: '$font-latin-sans-serif-1',
                              color: '$colour-brand-neutral-hue-1-shade-1', //colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                            }}
                            weight={LabelWeight['Semi-Bold']}
                          >
                            {basketTotalIncVAT}
                          </Label>
                        }
                      />
                    </SummaryDataWrapper>
                  </SummaryWrapper>

                  <DropdownMenuActions>
                    {placeOrderEnabled && (
                      <Label
                        size={LabelSize.Medium}
                        css={{ width: '100%' }}
                        weight={LabelWeight['Semi-Bold']}
                        lineHeight={LabelLineHeight.Normal}
                      >
                        <Button
                          size={ButtonSize['Small']}
                          variant={ButtonVariant.Primary}
                          radii={ButtonRadii['Small']}
                          data-cy="basket-dropdown-place-order-button"
                          css={{
                            marginBottom: 4,
                            width: '100%',
                          }}
                          disabled={
                            !visibleBasketLines.length ||
                            isPlacingOrder ||
                            !isBatchOrderCorrect
                          }
                          onClick={onPlaceOrder}
                        >
                          {isPlacingOrder
                            ? t('basketDropdown.placingOrder')
                            : t('basketDropdown.placeOrder', {
                                count: distinctNumberOfLocations,
                              })}
                        </Button>
                      </Label>
                    )}
                    <Label
                      size={LabelSize.Medium}
                      css={{ width: '100%' }}
                      weight={LabelWeight['Semi-Bold']}
                      lineHeight={LabelLineHeight.Normal}
                    >
                      <Button
                        size={ButtonSize['Small']}
                        css={{
                          width: '100%',
                        }}
                        data-cy="basket-dropdown-view-basket-button"
                        textWeight={LabelWeight['Regular']}
                        variant={ButtonVariant.Secondary}
                        radii={ButtonRadii['Small']}
                        disabled={!visibleBasketLines.length}
                        onClick={viewBasket}
                      >
                        {t('basketDropdown.viewBasket')} (
                        {sortedBasketLines.length})
                      </Button>
                    </Label>
                    {!!selectedCredits && !!totalCredits && (
                      <PaymentDisclaimer>
                        <div style={{ minWidth: '20px' }}>
                          <Icon
                            css={{
                              color: '$colour-brand-blue-hue-1-base',
                              fontFamily: '$font-latin-sans-serif-1',
                            }}
                            name="icon-alert-information-fill"
                          />
                        </div>
                        <Label
                          size={LabelSize.Small}
                          css={{
                            color: '$colour-brand-neutral-hue-1-base',
                            fontFamily: '$font-latin-sans-serif-1',
                          }}
                        >
                          {t('basketDropdown.creditUsed', {
                            creditUsed: getPrice(selectedCredits, currencyCode),
                            creditAvailable: getPrice(
                              totalCredits,
                              currencyCode
                            ),
                          })}
                        </Label>
                      </PaymentDisclaimer>
                    )}
                    <PaymentDisclaimer>
                      <div style={{ minWidth: '20px' }}>
                        <Icon
                          css={{
                            color: '$colour-brand-blue-hue-1-base',
                            fontFamily: '$font-latin-sans-serif-1',
                          }}
                          name="icon-alert-information-fill"
                        />
                      </div>
                      <Label
                        size={LabelSize.Small}
                        css={{
                          color: '$colour-brand-neutral-hue-1-base',
                          fontFamily: '$font-latin-sans-serif-1',
                        }}
                      >
                        {currentOrganisation?.config?.paymentDisclaimer}
                      </Label>
                    </PaymentDisclaimer>
                  </DropdownMenuActions>
                </>
              )}
            </ScrollAreaViewport>
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
};

export default NavBasketDropdown;
