import { CreditNote } from '../../modules/products/viewProducts/hooks/useGetCredits';

export interface CreditUsed {
  creditUsed: number;
  creditNoteId: string;
}

export const getCreditNotesUsed = (
  creditNotes: CreditNote[],
  selectedCredits: number
): CreditUsed[] => {
  let totalCreditsUsed = 0;

  return creditNotes.reduce<CreditUsed[]>((accumulator, creditNote) => {
    if (totalCreditsUsed >= selectedCredits) {
      return accumulator;
    }

    const remainingRequiredCredit = selectedCredits - totalCreditsUsed;
    const creditToUse = Math.min(
      creditNote.RemainingCredit,
      remainingRequiredCredit
    );

    accumulator.push({
      creditUsed: Math.round(creditToUse * 100) / 100,
      creditNoteId: creditNote.CreditNoteID,
    });

    totalCreditsUsed += creditToUse;

    return accumulator;
  }, []);
};
