import axios from 'axios';
import { useState } from 'react';
import config from '../../../config';
import { CreateClientOrderPayload } from './utils';

export interface CreateClientOrderResponse {
  createdClientOrders?: {
    clientOrderId: string;
    deliveryTimeline: {
      contractualExpectedDeliveryDate: string;
      min: number;
      max: number;
      units: string;
      businessDaysOnly: boolean;
    };
  }[];
}

export interface PlacingOrderState {
  isPlacingOrder: boolean;
  isSavingDraftOrder: boolean;
  placingOrderError: unknown;
  savingDraftOrderError: unknown;
  placingOrderData: null | CreateClientOrderResponse;
}

export const DEFAULT_PLACING_ORDER_STATE: PlacingOrderState = {
  isPlacingOrder: false,
  isSavingDraftOrder: false,
  placingOrderError: null,
  savingDraftOrderError: null,
  placingOrderData: null,
};

export const createDefaultPlacingOrderState = () => DEFAULT_PLACING_ORDER_STATE;

export interface UsePlaceOrderResult extends PlacingOrderState {
  placeOrder: (payload: CreateClientOrderPayload) => Promise<
    CreateClientOrderResponse & {
      hasError: boolean;
    }
  >;
}

export const usePlaceOrder = (): UsePlaceOrderResult => {
  const [placingOrderState, setPlacingOrderState] = useState<PlacingOrderState>(
    createDefaultPlacingOrderState()
  );
  return {
    ...placingOrderState,
    placeOrder: async ({
      clientOrders,
      isDraft,
      creditUsed,
    }: CreateClientOrderPayload) => {
      const truePatchState = isDraft
        ? { isSavingDraftOrder: true }
        : { isPlacingOrder: true };

      const falsePatchState = isDraft
        ? { isSavingDraftOrder: false }
        : { isPlacingOrder: false };

      setPlacingOrderState({
        ...createDefaultPlacingOrderState(),
        ...truePatchState,
      });

      try {
        const response = await axios.post(
          `${config.apiOriginUrl}/stock-management-api/api/v1/client-orders`,
          { clientOrders, isDraft, creditUsed }
        );

        const createdClientOrders = response.data || [];

        setPlacingOrderState({
          ...createDefaultPlacingOrderState(),
          ...falsePatchState,
          placingOrderData: {
            createdClientOrders,
          },
        });

        return { hasError: false, createdClientOrders };
      } catch (error) {
        const errorPatchState = isDraft
          ? { savingDraftOrderError: error }
          : { placingOrderError: error };

        setPlacingOrderState({
          ...createDefaultPlacingOrderState(),
          ...errorPatchState,
        });

        return { hasError: true };
      }
    },
  };
};
