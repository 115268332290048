import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import config from '../../../config';
import useCurrentOrganisationProducts from '../../../modules/products/viewProducts/graphql/hooks/useCurrentOrganisationProducts/useCurrentOrganisationProducts';
import {
  CreditNote,
  useGetCredits,
} from '../../../modules/products/viewProducts/hooks/useGetCredits';
import { Store, createStore } from '../../stores';
import { Address } from '../../types/Address';
import { Organisation } from '../../types/Organisation';
import { User } from '../../types/User';
import { getTaxRate } from '../../utils/getTaxRate/getTaxRate';
import { useOrganisationsContext } from '../UserOrganisationsProvider/UserOrganisationsProvider';
import { useUserContext } from '../UserProvider/UserProvider';
import {
  BasketState,
  UseBasketResult,
  createDefaultBasketState,
  useBasket,
} from './useBasket';

export interface Options {
  children: React.ReactElement;
}

export type NumberOfDays = number;

export enum DeliveryTimelineType {
  DAYS_IN_RANGE = 'daysInRange',
  DAYS = 'days',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeliveryTimelineValue = any;
// TODO: find better way of retrieving conditional types
// | { minDays: NumberOfDays; maxDays: NumberOfDays, estimatedDeliveryDate }
// | { minDays: NumberOfDays };

export interface DeliveryTimeline {
  contractualExpectedDeliveryDate?: Date;
  min?: null | number;
  max?: null | number;
  units?: null | string;
  businessDaysOnly?: null | boolean;
}
export interface DeliveryInfo {
  addressId: string;
  deliveryFee: number;
  deliveryTimeline: DeliveryTimeline;
}

export enum CreditSelectMode {
  useAvailableCredits = 'useAvailableCredits',
  useSelectedCredits = 'useSelectedCredits',
  dontUseCredits = 'dontUseCredits',
}

export interface BasketContextInterface extends UseBasketResult {
  deliveryInformations: DeliveryInfo[];
  totalCredits: number | null;
  selectedCredits: number | null;
  handleSelectCredits: (credits: number) => void;
  creditSelectMode: CreditSelectMode;
  handleSelectCreditSelectMode: (mode: CreditSelectMode) => void;
  usableCredits: number | null;
  creditNotes: CreditNote[];
}

const DEFAULT_CONTEXT: BasketContextInterface = {
  ...createDefaultBasketState(),
  addProductToBasket: () => null,
  editProductInBasket: () => null,
  markProductAsDeletedInBasket: () => null,
  markProductAsNonDeletedInBasket: () => null,
  isProductInBasket: () => false,
  getProductAddressesLines: () => [],
  removeMarkAsDeletedProducts: () => null,
  removeBasketOrderByAddressId: () => null,
  removeProductFromAddress: () => null,
  editProductQuantityInBasket: () => null,
  handleClientReferenceChange: () => null,
  handleAddAttachment: () => null,
  handleRemoveAttachment: () => null,
  resetBasketState: () => null,
  deliveryInformations: [],
  totalCredits: null,
  selectedCredits: null,
  usableCredits: null,
  handleSelectCredits: () => null,
  creditSelectMode: CreditSelectMode.useAvailableCredits,
  handleSelectCreditSelectMode: () => null,
  creditNotes: [],
};

export const BasketContext =
  createContext<BasketContextInterface>(DEFAULT_CONTEXT);

export const useBasketContext = () => useContext(BasketContext);

export const BASKET_KEY_NAME = '__stock_management_basket__';

export const basketStore: Store<BasketState> = createStore<BasketState>();

export interface GetBasketKeyOptions {
  organisation?: null | Organisation;
  user?: null | User;
}

const getBasketKey = ({ organisation, user }: GetBasketKeyOptions) => {
  if (!organisation?.id || !user?.id) {
    return null;
  }

  return `${BASKET_KEY_NAME}_${organisation.id}_${user.id}`;
};

export const BasketProvider = ({ children, ...props }: Options) => {
  const { products, productsLoading } = useCurrentOrganisationProducts();

  const { currentOrganisation } = useOrganisationsContext();
  const { user } = useUserContext();

  const isLoadingBasket = productsLoading || !currentOrganisation;

  const addresses = Array.isArray(currentOrganisation?.addresses)
    ? currentOrganisation?.addresses
    : [];

  const deliveryInformations = useMemo(
    () =>
      (addresses as Address[]).map(address => ({
        addressId: address.id as string,
        deliveryFee: address.deliveryFee as number,
        deliveryTimeline: address.deliveryTimeline as DeliveryTimeline,
      })),
    [addresses]
  );

  const VAT =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'VAT').taxValue || 0;
  const tariff =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'Tariff').taxValue ||
    0;
  const currencyCode = currentOrganisation?.defaultCurrency || 'GBP';

  const { totalCredits, credits } = useGetCredits({
    email: user?.email || '',
    orgName:
      currentOrganisation && currentOrganisation.config
        ? currentOrganisation.config?.xeroClientName
        : '',
    currency: currencyCode,
  });

  const basketKey = getBasketKey({ organisation: currentOrganisation, user });

  const initialValue =
    !isLoadingBasket && basketKey ? basketStore.getItem(basketKey) : undefined;

  const basket = useBasket({
    products,
    deliveryInformations,
    initialValue,
    basketKey,
    computeDeliveryCostPerProduct:
      currentOrganisation?.config?.computeDeliveryCostPerProduct,
    onBasketChange: (basketState: BasketState) => {
      basketKey && basketStore.setItem(basketKey, basketState);
    },
    currencyCode,
    VAT,
    tariff,
  });

  // TODO: Remove this after creditsEnabled flag is removed
  const totalCreditsAfterCheck =
    config.creditsEnabled === 'true' ? totalCredits : 0;

  const usableCredits = useMemo(
    () =>
      totalCreditsAfterCheck
        ? Number(
            Math.min(
              totalCreditsAfterCheck,
              basket.basketSummary.basketTotalIncludingTaxes
            ).toFixed(2)
          )
        : 0,
    [totalCreditsAfterCheck, basket.basketSummary.basketTotalIncludingTaxes]
  );

  const [creditSelectMode, setCreditSelectMode] = useState<CreditSelectMode>(
    CreditSelectMode.useAvailableCredits
  );
  const [selectedCredits, setSelectedCredits] = useState(usableCredits);

  const handleSelectCredits = (credits: number) => {
    setSelectedCredits(credits);
  };

  const handleSelectCreditSelectMode = (mode: CreditSelectMode) => {
    setCreditSelectMode(mode);
  };

  useEffect(() => {
    if (creditSelectMode === CreditSelectMode.useAvailableCredits) {
      setSelectedCredits(usableCredits);
    } else if (creditSelectMode === CreditSelectMode.useSelectedCredits) {
      if (selectedCredits > usableCredits) {
        setSelectedCredits(Number(Number(usableCredits).toFixed(2)));
      }
    }
  }, [basket.basketSummary.basketTotalIncludingTaxes]);

  return (
    <BasketContext.Provider
      value={{
        ...basket,
        deliveryInformations,
        totalCredits: totalCreditsAfterCheck,
        usableCredits,
        selectedCredits,
        handleSelectCredits,
        creditSelectMode,
        handleSelectCreditSelectMode,
        creditNotes: credits?.CreditNotes || [],
      }}
      {...props}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
