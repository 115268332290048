import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../../config';
import { getTotalCredits } from '../helpers';

interface CreditNoteAllocationInvoice {
  CreditNotes: [];
  HasAttachments: boolean;
  HasErrors: boolean;
  InvoiceID: string;
  InvoiceNumber: string;
  IsDiscounted: boolean;
  LineItems: [];
  Overpayments: [];
  Payments: [];
  Prepayments: [];
}

interface CreditNoteAllocation {
  Amount: number;
  Date: string;
  Invoice: CreditNoteAllocationInvoice;
}

interface CreditNoteContact {
  Addresses: [];
  ContactGroups: [];
  ContactID: string;
  ContactNumber: string;
  ContactPersons: [];
  HasAttachments: boolean;
  HasValidationErrors: boolean;
  Name: string;
  Phones: [];
}

export interface CreditNote {
  Allocations: CreditNoteAllocation[];
  BrandingThemeID: string;
  Contact: CreditNoteContact;
  CreditNoteID: string;
  CreditNoteNumber: string;
  CurrencyCode: string;
  CurrencyRate: number;
  Date: string;
  HasAttachments: boolean;
  HasErrors: boolean;
  LineAmountTypes: string;
  LineItems: [];
  Payments: [];
  Reference: string;
  RemainingCredit: number;
  Status: string;
  SubTotal: number;
  Total: number;
  TotalTax: number;
  Type: string;
  UpdatedDateUTC: string;
}

export interface GetCreditsResponse {
  CreditNotes: CreditNote[];
}

export interface UseGetCreditsResult {
  creditsLoading: boolean;
  creditsError: unknown;
  credits: null | GetCreditsResponse;
  totalCredits: null | number;
}

interface UseGetCreditsProps {
  email: string;
  orgName: string;
  currency: string;
}

export const useGetCredits = ({
  email,
  orgName,
  currency,
}: UseGetCreditsProps): UseGetCreditsResult => {
  const [creditsLoading, setCreditsLoading] = useState<boolean>(false);
  const [credits, setCredits] = useState<GetCreditsResponse | null>(null);
  const [creditsError, setCreditsError] = useState<boolean>(false);

  useEffect(() => {
    const getCredits = async () => {
      if (!email || !orgName || !currency) return;
      try {
        const response = await axios.get(
          `${config.orderServiceBaseUrl}/order-service/xero-api/get-credits?email_address=${email}&org_name=${orgName}&currency=${currency}`
        );
        return response.data;
      } catch (error) {
        return error;
      }
    };

    setCreditsLoading(true);

    getCredits()
      .then(response => {
        setCredits(response);
        setCreditsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setCreditsError(true);
        setCreditsLoading(false);
      });
  }, [email, orgName, currency]);

  return {
    creditsLoading,
    creditsError,
    credits,
    totalCredits: credits ? getTotalCredits(credits) : null,
  };
};
